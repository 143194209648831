import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import { DiscussionEmbed } from 'disqus-react';
import MDXRenderer from 'gatsby-mdx/mdx-renderer';

import Bio from '../components/Bio';
import Layout from '../components/layout';
import { rhythm, scale } from '../utils/typography';

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.mdx;
        const siteTitle = get(this.props, 'data.site.siteMetadata.title');
        const siteDescription = post.excerpt;
        const { previous, next } = this.props.pageContext;
        const disqusShortname = 'nigelgordon-blog';
        const disqusConfig = {
            identifier : post.id,
            title      : post.frontmatter.title
        };

        return (
            <Layout location={this.props.location}>
                <Helmet
                    htmlAttributes={{ lang: 'en' }}
                    meta={[
                        { name: 'description', content: siteDescription }
                    ]}
                    title={`${post.frontmatter.title} | ${siteTitle}`}
                />
                <h1>{post.frontmatter.title}</h1>
                <p
                    style={{
                        ...scale(-1 / 5),
                        display      : 'block',
                        marginBottom : rhythm(1),
                        marginTop    : rhythm(-1)
                    }}
                >
                    {post.frontmatter.date}
                </p>
                <MDXRenderer>{post.code.body}</MDXRenderer>
                <hr
                    style={{
                        marginBottom : rhythm(1)
                    }}
                />
                <Bio />

                <ul
                    style={{
                        display        : 'flex',
                        flexWrap       : 'wrap',
                        justifyContent : 'space-between',
                        listStyle      : 'none',
                        padding        : 0
                    }}
                >
                    {previous && (
                        <li>
                            <Link to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        </li>
                    )}

                    {next && (
                        <li>
                            <Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        </li>
                    )}
                </ul>
                <DiscussionEmbed
                    shortname={disqusShortname}
                    config={disqusConfig}
                />
            </Layout>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        mdx(fields: { slug: { eq: $slug } }) {
            code {
                body
                scope
            }
            id
            excerpt
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
            }
        }
    }
`;
